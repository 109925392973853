const mouseClickEvents = ["mousedown", "click", "mouseup"];
export function simulateMouseClick(element: Element) {
  for (const mouseEventType of mouseClickEvents) {
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  }
}

/**
 * Simulates click on element based on querySelector parameters
 * @param ref RefObject surrounding element
 * @param selector  css selector - https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors#basic_selectors
 * @returns void
 */
export const simulateClickOnEle = (ref: React.RefObject<HTMLElement>, selector: string) => {
  const ele = ref.current?.querySelector(selector)!;
  if (ele) {
    simulateMouseClick(ele);
  }
};

export function waitForEle(selector: string) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
