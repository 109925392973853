import { ValidSortMethods } from "@smartrr/shared/utils/sortBy";

import { AvailableLanguages, ICustomerPortalTheme, SECTION, ThemeVersion } from "../entities/CustomerPortalTheme";
import { RequireNilFields } from "../utils/RequireNilFields";

export const DEFAULT_PRIMARY_COLOR = "#67696C";
export const DEFAULT_SECONDARY_COLOR = "#F1F2F3";
export const DEFAULT_ACCENT_COLOR = "#313943";

export const defaultCustomerPortalThemeConfig: RequireNilFields<ICustomerPortalTheme> = {
  overrideCSS: "",
  themeName: ThemeVersion.MODERN,

  // Modern Theming Defaults
  // Main Colors
  modernPageBackground: "#F4F4F4",
  modernCardBackground: "#FFFFFF",
  modernMenuBackground: "#FFFFFF",
  modernBadgeBackground: "#F4F4F4",
  modernIconsColor: "#202223",
  modernLineDividers: "#E4E4E4",

  // Borders
  modernCardRadius: 8,
  modernCardBorder: 0,
  modernCardBorderColor: "#FFFFFF",
  modernSmallCardBorderRadius: 25,
  modernSmallCardBorderWidth: 1,
  modernSmallCardBorderColor: "#C9C9C9",

  // Buttons
  modernPrimaryButtonColor: "#2E2E2E",
  modernPrimaryButtonBorderColor: "#2E2E2E",
  modernSecondaryButtonColor: "#FFFFFF",
  modernSecondaryButtonBorderColor: "#C9C9C9",
  modernButtonBorderWidth: 1,
  modernButtonBorderRadius: 20,
  modernButtonBorderHeight: 40,

  isPasswordless: false,
  passwordlessStrategy: "magic-link",
  lastSavedThemeId: "",

  // Sections Object
  modernThemeSettings: {
    sections: {
      banner: {
        display: true,
        text: "",
        deleted: false,
      },
      subscriptions: {
        display: true,
        addOns: true,
        deleted: false,
        altView1: false,
      },
      quickBuy: {
        deleted: false,
        display: true,
      },
      orderHistory: {
        display: true,
        deleted: false,
      },
      rewards: {
        display: false,
        deleted: false,
      },
      reviews: {
        display: true,
        deleted: false,
      },
      wishlist: {
        display: true,
        deleted: false,
      },
      settings: {
        display: true,
        deleted: false,
      },
      instagram: {
        display: false,
      },
      trending: {
        display: false,
        heading: "",
        caption: "",
        showHeart: false,
        imgUrl: "",
        sortMethod: ValidSortMethods.MANUAL,
      },
    },
    sectionsOrder: [
      SECTION.SUBSCRIPTION,
      SECTION.LOYALTY,
      SECTION.ORDERHISTORY,
      SECTION.SETTINGS,
      SECTION.INSTAGRAM,
      SECTION.TRENDING,
    ],

    welcomeInfo: {
      welcomeMessage: "Hi",
      customerFirstName: true,
      customerEmail: true,
      customerSinceDate: true,
    },
    nextOrderActions: {
      sendNow: true,
      skip: true,
      gift: true,
      swap: true,
      delay: true,
      editAddress: true,
    },
    subscriptionActions: {
      editFrequency: true,
      setNextOrderDate: true,
      editSubscriptionAddress: true,
      editPayment: true,
      pause: true,
      showVariantName: true,
      subscriptionSwap: true,
    },
    showHide: {
      showVariantName: true,
      showPortalMenu: true,
      showUpcomingOrders: true,
      showDiscountCodeInput: true,
      showSectionTitles: true,
      showAddItemAsSub: true,
    },
    productScrollType: "horizontal",
    fontSizingType: "static",
  },

  // Translations
  translationOverrides: {
    activeLanguage: AvailableLanguages.ENGLISH,
    en: {},

    //* Currently all languages are defaulted to english, language specific defaults to come
    fr: {},
    es: {},
    pt: {},
  },

  // modern badge
  modernActiveBadgeColor: "#E8FFDA",
  modernPausedBadgeColor: "#FFF5DA",
  modernCancelledBadgeColor: "#FFDADA",

  // banner
  modernBannerColor: "#FFFFFF",

  // Product Images
  modernImageBorderRadius: 25,
  modernSmallImageBorderRadius: 12,

  modernOverrideCSS: "",

  // Fonts and Typography

  //XLarge Fonts
  modernExtraLargeFontFamily: "Inter",
  modernExtraLargeFontSize: 42,
  modernExtraLargeFontWeight: "bold",
  modernExtraLargeFontLineHeight: 44,
  modernExtraLargeFontLetterSpacing: 0,
  modernExtraLargeFontColor: "#202223",

  //Large Fonts
  modernLargeFontFamily: "Inter",
  modernLargeFontSize: 28,
  modernLargeFontWeight: "bold",
  modernLargeFontLineHeight: 32,
  modernLargeFontLetterSpacing: 0,
  modernLargeFontColor: "#202223",

  //Medium Fonts
  modernMediumFontFamily: "Inter",
  modernMediumFontSize: 26,
  modernMediumFontWeight: "normal",
  modernMediumFontLineHeight: 32,
  modernMediumFontLetterSpacing: 0,
  modernMediumFontColor: "#202223",

  //small
  modernSmallFontFamily: "Inter",
  modernSmallFontSize: 20,
  modernSmallFontWeight: "normal",
  modernSmallFontLineHeight: 28,
  modernSmallFontLetterSpacing: 0,
  modernSmallFontColor: "#202223",

  //subheading
  modernSubHeadingFontFamily: "Inter",
  modernSubHeadingFontSize: 12,
  modernSubHeadingFontWeight: "bold",
  modernSubHeadingFontLineHeight: 16,
  modernSubHeadingFontLetterSpacing: 0,
  modernSubHeadingFontColor: "#202223",
  isSubHeadingUppercase: false,

  //body
  modernBodyFontFamily: "Inter",
  modernBodyFontSize: 14,
  modernBodyFontWeight: "normal",
  modernBodyFontLineHeight: 20,
  modernBodyFontLetterSpacing: 0,
  modernBodyFontColor: "#202223",

  // caption
  modernCaptionFontFamily: "Inter",
  modernCaptionFontSize: 12,
  modernCaptionFontWeight: "normal",
  modernCaptionFontLineHeight: 16,
  modernCaptionFontLetterSpacing: 0,
  modernCaptionFontColor: "#202223",

  // button CTA
  modernButtonCTAFontFamily: "Inter",
  modernButtonCTAFontSize: 14,
  modernButtonCTAFontWeight: "normal",
  modernButtonCTAFontLineHeight: 0,
  modernButtonCTAFontLetterSpacing: 0,
  modernPrimaryButtonFontColor: "#FFFFFF",
  modernSecondaryButtonFontColor: "#202223",
};

export const updatedSections = {
  updatedSectionValues: {
    banner: {
      display: true,
      text: "",
      deleted: false,
    },
    subscriptions: {
      display: true,
      addOns: true,
      deleted: false,
      altView1: false,
    },
    quickBuy: {
      deleted: false,
      display: true,
    },
    orderHistory: {
      display: true,
      deleted: false,
    },
    rewards: {
      display: false,
      deleted: false,
    },
    reviews: {
      display: true,
      deleted: false,
    },
    wishlist: {
      display: true,
      deleted: false,
    },
    settings: {
      display: true,
      deleted: false,
    },
    instagram: {
      display: false,
    },
    trending: {
      display: false,
      heading: "",
      caption: "",
      showHeart: false,
      imgUrl: "",
      sortMethod: ValidSortMethods.MANUAL,
    },
  },
  updatedSectionOrder: [
    SECTION.SUBSCRIPTION,
    SECTION.ORDERHISTORY,
    SECTION.SETTINGS,
    SECTION.INSTAGRAM,
    SECTION.TRENDING,
  ],
};
